var List = function() {
    var pub = {};

    var defaults = {
        event: {
            prefix: ''
        },
        rest: {
            url: {
                base: '',
                data: ''
            }
        },
        dom: {
            container: ''
        },
        table: {
            columns: [
                { title: 'UUID', data: 'uuid', type: 'normal' },
            ],
            defaultOrder: [[0, 'asc']]
        }
    }

    var options = {};

    var elements = {
        container: false,
        table: false
    };

    var dataTable;

    var selectedUuid = false;

    var renderPreformattedColumn = function(data, type, row, meta) {
        // replace \n with <br />
        return '<pre>' + data.replace(/\n/g, '<br />') + '</pre>';
    };

    pub.init = function(overrides) {
        options = $.extend(true, {}, defaults, overrides);

        initElements();
        initDataTable();
        initEventHandlers();
    };

    pub.on = function(event, handler) {
        PubSub.subscribe(getEventName(event), handler);
    }

    pub.refresh = function() {
        selectedUuid = false;
        dataTable.ajax.reload();
    }

    pub.getSelectedUuid = function() {
        return selectedUuid;
    }

    function initElements() {
        elements.container = $('#' + options.dom.container);

        elements.table = $('<table />')
            .addClass('table')
            .addClass('full-width');

        elements.container.append(elements.table);
    }

    function initDataTable() {
        var columnDefs = buildColumnDefs();

        dataTable = elements.table.DataTable({
            autoWidth: false,
            dom: 'trpi',
            paging: true,
            pagingType: 'numbers',
            ajax: getDataUrl(),
            columns: options.table.columns,
            columnDefs: columnDefs,
            createdRow: function (row, data, rowIndex) {
                $(row).attr('data-uuid', data.uuid);
            },
            order: options.table.defaultOrder
        });
    }

    function buildColumnDefs() {
        var timestampColumns = [];
        var hiddenColumns = [];
        var preformattedColumns = [];
        

        var columns = options.table.columns;

        for (var i = 0; i < columns.length; i++) {
            if (columns[i].type === 'timestamp') {
                timestampColumns.push(i);
            }

            if (columns[i].type === 'hidden') {
                hiddenColumns.push(i);
            }

            if (columns[i].type == 'preformatted') {
                preformattedColumns.push(i);
            }
        }

        var columnDefs = [];

        if (timestampColumns.length > 0) {
            columnDefs.push(columnDefs, { targets: timestampColumns, render: $.fn.dataTable.render.moment('X', 'YYYY-MM-DD HH:mm:ss') });
        }

        if (hiddenColumns.length > 0) {
            columnDefs.push(columnDefs, { targets: hiddenColumns, visible: false });
        }

        if (preformattedColumns.length > 0) {
            columnDefs.push(columnDefs, { targets: preformattedColumns, render: renderPreformattedColumn });
        }

        return columnDefs;
    }

    function initEventHandlers() {
        $('#' + options.dom.container + ' table tbody').on('click', 'tr', onBodyRowClicked);
    }

    function onBodyRowClicked() {
        if ($(this).hasClass('selected')) {
            // already selected, do nothing
        } else {
            dataTable.$('tr.selected').removeClass('selected');
            $(this).addClass('selected');

            var uuid = $(this).attr('data-uuid');

            selectedUuid = uuid;

            PubSub.publish(getEventName('rowSelected'), uuid);
        }
    }

    function getEventName(event) {
        return options.event.prefix + '.' + event;
    }

    function getDataUrl() {
        return options.rest.url.base + options.rest.url.data;
    }

    return pub;
};

