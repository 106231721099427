// @Deprecated
var Administration = function() {
    var pub = {};

    var defaults = {
        rest: {
            url: {
                base: '',
                user: '/user',
            }
        },
        dom: {
        }
    }

    var options = {};

    var elements = {
    };

    pub.init = function(overrides) {
        options = $.extend(true, {}, defaults, overrides);

        initElements();
    };

    function initElements() {
    }

    function UserUrl(uuid) {
        return options.rest.url.base + options.rest.url.user + '/' + uuid;
    }

    return pub;
};
