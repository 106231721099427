var Application = function () {
    var pub = {};

    var defaults = {
        rest: {
            url: {
                base: '',
                altBase: '',
                changePassword: ''
            }
        }
    }

    var options = {};

    var measurementsList = false;
    var measurement = false;

    var vehiclesList = false;
    var vehicle = false;

    var calibrationsList = false;

    var usersList = false;
    var administration = false;

    var showFriction = 'measurement-show-contaminant'
    var showContaminant = 'measurement-show-friction'

    var frictionElement = $('#' + showFriction);
    var contaminantElement = $('#' + showContaminant);

    pub.init = function (overrides) {
        options = $.extend({}, defaults, overrides);

        initMeasurementsList();
        initMeasurement();

        initVehiclesList();
        initVehicle();

        initCalibrationsList();

        if (options.rest.url.altBase.length) {
            initUsersList();
        }

        initEventHandlers();
    };

    function initVehiclesList() {
        vehiclesList = List();
        vehiclesList.init({
            event: {
                prefix: 'VehiclesList'
            },
            rest: {
                url: {
                    base: options.rest.url.base,
                    data: '/vehicle/peek'
                }
            },
            dom: {
                container: 'list-vehicles'
            },
            table: {
                columns: [
                    { title: 'uuid', data: 'uuid' }
                ]
            },
            defaultOrder: [[1, 'desc']]
        });

        vehiclesList.on('rowSelected', onVehicleSelected);
    }

    function initVehicle() {

    }

    function initMeasurementsList() {
        measurementsList = List();
        measurementsList.init({
            event: {
                prefix: 'MeasurementsList'
            },
            rest: {
                url: {
                    base: options.rest.url.base,
                    data: '/measurement/peek'
                }
            },
            dom: {
                container: 'list-measurements'
            },
            table: {
                columns: [
                    { title: 'uuid', data: 'uuid', type: 'hidden' },
                    { title: 'Date', data: 'started', type: 'timestamp' },
                    { title: 'Surface', data: 'surfaceName', type: 'normal' },
                ],
                defaultOrder: [[1, 'desc']]
            }
        });

        measurementsList.on('rowSelected', onMeasurementSelected);
    }

    function initCalibrationsList() {
        calibrationsList = List();
        calibrationsList.init({
            event: {
                prefix: 'CalibrationsList'
            },
            rest: {
                url: {
                    base: options.rest.url.base,
                    data: '/calibration'
                }
            },
            dom: {
                container: 'list-calibrations'
            },
            table: {
                columns: [
                    { title: 'uuid', data: 'uuid', type: 'hidden' },
                    { title: 'Date', data: 'calibrationDate', type: 'timestamp' },
                    { title: 'Type', data: 'type', type: 'normal' },
                    { title: 'Data', data: 'displayData', type: 'preformatted' }
                ],
                defaultOrder: [[1, 'desc']]
            }
        });
    }

    function initUsersList() {
        usersList = List();
        usersList.init({
            event: {
                prefix: 'UsersList'
            },
            rest: {
                url: {
                    base: options.rest.url.altBase,
                    data: '/user'
                }
            },
            dom: {
                container: 'list-users'
            },
            table: {
                columns: [
                    { title: 'uuid', data: 'uuid', type: 'hidden' },
                    { title: 'Name', data: 'name', type: 'normal' },
                    { title: 'Email', data: 'email', type: 'normal' },
                    { title: 'Domains', data: 'domains', type: 'normal' },
                    { title: 'Created at', data: 'createdAt', type: 'timestamp' },
                    { title: 'User Type', data: 'userType', type: 'normal' }
                ],
                defaultOrder: [[5, 'desc']]
            }
        });
    }

    function initAdministration() {
        administration = Administration();
        administration.init({
            rest: {
                url: {
                    base: options.rest.url.altBase
                }
            }
        });
    }

    function initMeasurement() {
        measurement = Measurement();
        measurement.init({
            rest: {
                url: {
                    base: options.rest.url.base
                }
            }
        });
    }

    function initEventHandlers() {
        $('#button-refresh-measurements').on('click', onRefreshMeasurements);
        $('#button-refresh-vehicles').on('click', onRefreshVehicles);
        $('#button-refresh-calibrations').on('click', onRefreshCalibrations);
        $('#button-refresh-users').on('click', onRefreshUsers);

        // this is in measurement tab
        $('#measurement-button-download-csv').on('click', onMeasurementTabDownloadCsvButtonClicked)
        $('#measurement-button-remove').on('click', onMeasurementTabRemoveButtonClicked);
        $('#measurement-button-print').on('click', onMeasurementTabPrintButtonClicked);

        $('#administration-button-add-user').on('click', onAddUserButtonClicked);
        $('#administration-button-reset-password').on('click', onResetPasswordButtonClicked);
        $('#administration-button-make-premium').on('click', onMakePremiumButtonClicked);
        $('#administration-button-make-basic').on('click', onMakeBasicButtonClicked);

        // this is in the user menu
        $('#link-change-password').on('click', onUserMenuChangePasswordLinkClicked);

        // this is in the download csv selection modal
        $('#button-measurement-download-friction-csv').on('click', onFrictionDownloadSelected);
        $('#button-measurement-download-contaminant-csv').on('click', onContaminantDownloadSelected);


        // this is in in download measurement csv modal
        $('#button-measurement-csv-download').on('click', onDownloadMeasurementCsv);
        $('#button-measurement-pdf-download').on('click', onDownloadMeasurementPdf);

        $('#button-measurement-remove').on('click', onRemoveMeasurement);
        $('#button-change-password').on('click', onChangePassword);

        // this is in the snowtam section
        $('#measurement-button-download-snowtam-pdf').on('click', onDownloadMeasurementSnowtamPdf);

        // this is in the add uadministration add user modal
        $('#button-administration-add-user').on('click', onAddUser);

        $('#modal-add-user').on('hidden.bs.modal', function (event) {
            $('#form-add-user').parsley().reset();
            $('#modal-add-user input').val('');
        });

        $('#modal-change-password').on('hidden.bs.modal', function (event) {
            $('#form-change-password').parsley().reset();
            $('#modal-change-password input').val('');
        });
    }

    function onVehicleSelected(message, uuid) {

    }

    function onMeasurementSelected(message, uuid) {
        measurement.show(uuid);
    }

    function onRefreshMeasurements() {
        measurementsList.refresh();
    }

    function onRefreshVehicles() {
        vehiclesList.refresh();
    }

    function onRefreshCalibrations() {
        calibrationsList.refresh();
    }

    function onRefreshUsers() {
        usersList.refresh();
    }

    function onMeasurementTabDownloadCsvButtonClicked() {
        if (measurement.isContaminantMeasurement()) {
            showModal('modal-select-download-csv');
        } else{
            showModal('modal-download-measurement-csv');
        }
    }

    function onMeasurementTabRemoveButtonClicked() {
        showModal('modal-remove-measurement');
    }

    function onMeasurementTabPrintButtonClicked() {
        measurement.print();
    }

    function onContaminantDownloadSelected() {
        measurement.downloadContaminantCsv();
    }

    function onFrictionDownloadSelected() {
        hideModal('modal-select-download-csv')
        showModal('modal-download-measurement-csv')
    }

    function onUserMenuChangePasswordLinkClicked() {
        showModal('modal-change-password');
    }

    function onAddUserButtonClicked() {
        showModal('modal-add-user');
    }

    function onResetPasswordButtonClicked() {
        var uuid = usersList.getSelectedUuid();

        var name = $('#list-users tr[data-uuid="' + uuid + '"]').children().first().text();

        if (name) {
            if (confirm('Are you sure you want to reset password for user: ' + name)) {
                var options = {
                    url: buildResetUserPasswordUrl(uuid),
                    type: 'GET'
                };

                $.ajax(options)
                    .done(function (data) {
                        if (data.status === 'success') {
                            prompt('Password reseted to', data.data.password);
                        } else {
                            alert('Password reset failed: ' + data.message);
                        }
                    });
            }
        }
    }

    function onMakePremiumButtonClicked() {
        var uuid = usersList.getSelectedUuid();

        var name = $('#list-users tr[data-uuid="' + uuid + '"]').children().first().text();

        if (name) {
            if (confirm('Are you sure you want to set this user as premium: ' + name)) {
                
                var options = {
                    url: buildMakeUserPremiumUrl(uuid),
                    type: 'POST'
                };

                $.ajax(options)
                    .done(function (data) {
                        if (data.status === 'success') {
                            alert('Set user as premium', data.data.email);
                            usersList.refresh();
                        } else {
                            alert('Failed: ' + data.message);
                        }
                    });
            
            }
        }
    }

    function onMakeBasicButtonClicked() {
        var uuid = usersList.getSelectedUuid();

        var name = $('#list-users tr[data-uuid="' + uuid + '"]').children().first().text();

        if (name) {
            if (confirm('Are you sure you want to set this user as basic: ' + name)) {
                var options = {
                    url: buildMakeUserPremiumUrl(uuid),
                    type: 'DELETE'
                };

                $.ajax(options)
                    .done(function (data) {
                        if (data.status === 'success') {
                            alert('Set user as Basic', data.data.email);
                            usersList.refresh();
                        } else {
                            alert('Failed: ' + data.message);
                        }
                    });

            }

        }
    }

    function onDownloadMeasurementCsv() {
        var resolution = $('#field-measurement-csv-resolution').val();
        var includeGpsPosition = $('#checkbox-measurement-csv-include-gps-position').is(':checked');

        measurement.downloadCsv(resolution, includeGpsPosition);
    }

    function onDownloadMeasurementPdf() {
        console.log("DOWNLOAD PDF");
        console.log(measurement)
        measurement.downloadPdf();
    }
    

    function onDownloadMeasurementSnowtamPdf() {
        measurement.downloadSnowtamPdf();
    }

    function onAddUser() {
        var form = $('#form-add-user').parsley();

        if (form.validate()) {
            var domainName = $('#field-add-user-domain-name').val();
            var name = $('#field-add-user-name').val();
            var email = $('#field-add-user-email').val();
            var password = $('#field-add-user-password').val();

            var options = {
                url: buildAddUserUrl(),
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({
                    domainName: domainName,
                    email: email,
                    password: password,
                    name: name
                })
            };

            $.ajax(options)
                .done(function (data) {
                    if (data.status === 'success') {
                        alert('User added');
                        hideModal('modal-change-password');
                        usersList.refresh();
                    } else {
                        alert('User add failed: ' + data.message);
                    }
                })
        }
    }

    function onChangePassword() {
        var form = $('#form-change-password').parsley();

        if (form.validate()) {
            var currentPassword = $('#field-user-current-password').val();
            var newPassword = $('#field-user-new-password').val();
            var confirmNewPassword = $('#field-user-confirm-new-password').val();

            var options = {
                url: buildChangeUserPasswordUrl(),
                type: 'POST',
                data: {
                    currentPassword: currentPassword,
                    newPassword: newPassword,
                    confirmNewPassword: confirmNewPassword
                }
            };

            $.ajax(options)
                .done(function (data) {
                    if (data.status === 'success') {
                        alert('Password changed');
                        hideModal('modal-change-password');
                    } else {
                        alert('Password change failed: ' + data.message);
                    }
                });
        }
    }

    function onRemoveMeasurement() {
        measurement.remove();
    }

    function showModal(id) {
        $('#' + id).modal({});
    }

    function hideModal(id) {
        $('#' + id).modal('hide');
    }

    function buildChangeUserPasswordUrl() {
        return options.rest.url.changePassword;
    }

    function buildResetUserPasswordUrl(uuid) {
        return options.rest.url.altBase + '/resetPassword/' + uuid;
    }

    function buildMakeUserPremiumUrl(uuid) {
        return options.rest.url.altBase + '/setPremium/' + uuid;
    }    

    function buildAddUserUrl() {
        return options.rest.url.altBase + '/user';
    }

    return pub;
};
